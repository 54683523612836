import Swiper from 'swiper/bundle';
import GET_ARTICLES from '../class/getArticles';

const [d, mq] = [document, window.matchMedia('(max-width: 900px)')];

class Blocks {
    constructor() {
        this.init();
    }

    init() {
        this.news('.block-news__list[data-plugin="getArticles"]');
        this.event('.block-event__list[data-plugin="getArticles"]');
    }

    mainvisual() {
        //メインスライダー
        new Swiper('.mainvisual-slider[data-plugin="mainSlider"]', {
            direction: 'vertical',
            loop: true,
            effect: 'fade',
            speed: 1500,
            fadeEffect: {
                crossFade: true,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                waitForTransition: false,
            },
            pagination: {
                el: '.mainvisual__slider-pager',
                clickable: true,
            },
        });
    }

    newsslider() {
        const swiper = new Swiper('.news-slider[data-plugin="newsSlider"]', {
            slidesPerView: 'auto',
            // slidesPerView: 1,
            // loop: true,
            spaceBetween: 23,
            grabCursor: true,
            // pagination: {
            //     el: '.news-slider .swiper-pagination',
            //     clickable: true,
            // },
            navigation: {
                nextEl: '.news-slider .swiper-button-next',
                prevEl: '.news-slider .swiper-button-prev',
            },
            breakpoints: {
                767: {
                    spaceBetween: 30,
                },
            },
        });
    }

    news(selector) {
        const news_block = new GET_ARTICLES(selector);
        news_block.makeItem = (item, content) => `
    <div class="block-${content}-item swiper-slide">
      <a href="/${content}/?mode=detail&amp;article=${
            item.id
        }" class="block-${content}-item__link">
          <div class="block-${content}-item__img">
            <img src="${item.thumb}" alt="${item.title}">
          </div>
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span><span class="cate">${
            item.category.name
        }</span>
          </div>
          <h4 class="title ${item.is_new ? 'is-new' : ''}">${
            mq.matches
                ? item.title.str_truncate(40)
                : item.title.str_truncate(34)
        }</h4>
        </div>
      </a>
    </div>
    `;
        // news_block.makeItem = (item) => console.log(item);
        news_block.render();
    }
    event(selector) {
        const event_block = new GET_ARTICLES(selector);
        const getCategories = this.fetchCategories('event');
        const categoryContainer = d.querySelectorAll('.block-event [data-plugin="getCategories"]');
        event_block.makeItem = (item, content) => `
    <div class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${
            item.id
        }" class="block-${content}-item__link">
        <h4 class="title ${item.is_new ? 'is-new' : ''}">${
            mq.matches
                ? item.title.str_truncate(40)
                : item.title.str_truncate(34)
        }</h4>
        <span class="cate">${item.category.name}</span>
        <p class="desc">${item.desc}</p>
        <div class="block-${content}-item__body">
          <div class="text-area">
            <p>${mq.matches ? item.body.str_truncate(110) : item.body.str_truncate(110)}</p>
          </div>
          <div class="block-${content}-item__img">
            <img src="${item.thumb}" alt="${item.title}">
          </div>
        </div>
      </a>
    </div>
    `;
        // event_block.makeItem = (item) => console.log(item);
        event_block.render();

        getCategories.then((cats) => {
            let cat = {
                all: {
                    id: 'all',
                    href: '/event/?mode=list',
                    name: 'ALL',
                    turn: 0,
                },
                // other: {
                //     id: 'other',
                //     href: '/event/?mode=list&cat=0',
                //     name: 'その他',
                //     turn: 99,
                // },
            };
            cats = [cat.all, ...cats, cat.other];
            const makeHTML = (cat) =>
                `<div class="block-event__categories-item is-${cat.id}"><a href="${cat.href}">${cat.name}</a></div>`;
            categoryContainer.forEach((obj) => {
                obj.innerHTML = '';
                cats.forEach((cat) =>
                    obj.insertAdjacentHTML('beforeend', makeHTML(cat))
                );
            });
        });
    }
    fetchCategories(content) {
        return fetch(`/assets/api/getCategories/?content=${content}`)
            .then((r) => r.json())
            .catch((e) => {
                throw new Error(e);
            });
    }

    fetchArticles(opt) {
        return fetch(`/assets/api/getArticles/?${new URLSearchParams(opt)}`)
            .then((r) => r.json())
            .catch((e) => {
                throw new Error(e);
            });
    }
}

// ECのRSSを取得
const renderXML = async (url) => {
    const getXML = await fetch(url);
    const parser = new DOMParser();
    const target = d.querySelector('#shopprofeed');
    let xml = await getXML.text();
    xml = await parser.parseFromString(xml, 'text/xml');
    $(xml)
        .find('item')
        .each(function () {
            const link = $(this).find('link').text();
            const title = $(this).find('title').text();
            const image = $(this)
                .find('foaf\\:Image')
                .attr('rdf:about');

            // 記事を表示
            const articleHTML = `
                    <div class="block-newitem-item swiper-slide">
                        <a href="${link}" class="block-newitem-item__link" target="_blank">
                            <div class="block-newitem-item__img">
                                <img src="${image}" alt="${title}">
                            </div>
                            <h3 class="title">${title}</h3>
                        </a>
                    </div>
                `;
            $(target).append(articleHTML);
        });

        // ECのRSSを取得後スライダーを実装
        swiper = new Swiper('.newitem-slider[data-plugin="feedSlider"]', {
            slidesPerView: 'auto',
            spaceBetween: 23,
            grabCursor: true,
            navigation: {
                nextEl: '.newitem-slider .swiper-button-next',
                prevEl: '.newitem-slider .swiper-button-prev',
            },
            breakpoints: {
                767: {
                    spaceBetween: 30,
                },
            },
        });
}

async function instaAPI() {
    // 子要素<ul>を追加
    // document.querySelector('#insta').insertAdjacentHTML('beforeend', '<ul></ul>');

    let cards = 21; // insta投稿の表示件数を指定
    let access_token =
        'EAAHltb9yPYQBAI4ZCm0oybqDrTfL02CJCnOulG1hicg5NeXOvKRPM1SGzVFJ8rTEQCAIoSLRWJZAppardZCw9QRQLYIXOK1UmSP1Ruun3TmBpA6JFZCfWVMIGmxZCUhcaJZCr0ZCdqR8sfW1Yv3s7sscz4X1kihWOrxZCMmyISKWwAZDZD'; // アクセストークン
    let business_id = '17841403016038672'; // アクセストークン
    const response = await fetch(
        `https://graph.facebook.com/v19.0/${business_id}?fields=name,media.limit(${cards}){ caption,media_url,thumbnail_url,permalink,like_count,comments_count,media_type}&access_token=${access_token}`
    );

    if (response.status === 200) {
        const resObjects = await response.json();
        // console.log(resObjects.media);
        //（挙動への影響は一切無いものの）オブジェクト{resObjects.media}内のプロパティ{paging}のせいで「instaItems[1]が無いというエラー」が出るので削除して以降の処理を進めていく
        delete resObjects.media.paging;

        Object.entries(resObjects.media).forEach((instaItems) => {
            // console.log(instaItems);
            instaItems[1].forEach((eachItem) => {
                if (eachItem.media_url !== null) {
                    // 投稿が動画か否かを判定して{media}を変更
                    if (eachItem.media_type === 'VIDEO') {
                        eachItem.media = eachItem.thumbnail_url;
                    } else {
                        eachItem.media = eachItem.media_url;
                    }

                    document
                        .querySelector('#instafeed')
                        .insertAdjacentHTML(
                            'beforeend',
                            `<li class="instafeed__item"><a href="${eachItem.permalink}" target="_blank" rel="noopener"><img src="${eachItem.media}"></a></li>`
                        );
                }
            });
        });
    } else {
        document
            .querySelector('#insta')
            .insertAdjacentHTML(
                'beforeend',
                `<p style="text-align:center;width:100%;">読み込めませんでした</p>`
            );
    }
}

const domReady = (TPL) => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.mainvisual();
        blocks.newsslider();
    });

    const btnClose = d.getElementById('btnclose'),
        fixBnr = d.getElementById('fixbnr'),
        pageTop = d.getElementById('pagetop');
    function clickEvent() {
        fixBnr.classList.add('is-close');
        pageTop.classList.add('is-bnrClose');
        console.log('ボタンクリック');
    }
    btnClose.onclick = clickEvent;

    // shop-proのRSSを表示する
    renderXML('/assets/php/shopprofeed.php');

    $.getScript('//connect.facebook.net/ja_JP/sdk.js', function () {
        FB.init({ appId: '759600244139396', xfbml: true, version: 'v2.8' });
    });

    instaAPI(); // 関数の実行
};

const jqInit = () => false;

export default function HOME() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('rwd002.beforeDomready', (e) => domReady(e.detail));

    $(() => jqInit());
}
