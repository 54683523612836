// import gsap, { Power4 } from 'gsap';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const gsapAnimation = () => {
    // const target = document.querySelector('.target');

    const targets = [
        { element: '.target-cafe-1', yStart: 40, yEnd: -40 },
        { element: '.target-cafe-2', yStart: 70, yEnd: -70 },
        { element: '.target-cafe-3', yStart: 20, yEnd: -20 },
        { element: '.target-cafe-4', yStart: 70, yEnd: -70 },
        { element: '.target-cafe-5', yStart: 30, yEnd: -30 },
        { element: '.target-cafe-6', yStart: 30, yEnd: -30 },
        { element: '.target-goods-1', yStart: 0, yEnd: 0 },
        { element: '.target-goods-2', yStart: 60, yEnd: -30 },
        { element: '.target-goods-3', yStart: 50, yEnd: -50 },
        { element: '.target-temiyage-1', yStart: 50, yEnd: -20 },
        { element: '.target-takeout-1', yStart: 50, yEnd: -40 },
        { element: '.target-takeout-2', yStart: 40, yEnd: -30 },
    ];

    // opacityの設定
    targets.forEach((target) => {
        gsap.to(target.element, {
            opacity: 0,
            scrollTrigger: {
                trigger: target.element,
                start: 'top 40%',
                end: 'top -10%',
                scrub: true,
                // markers: true,
            },
        });
    });

    // y軸の設定
    targets.forEach((target) => {
        gsap.fromTo(target.element,
            { y: target.yStart },
            { y: target.yEnd,
            scrollTrigger: {
                trigger: target.element,
                start: 'top 100%',
                end: 'top 0%',
                scrub: true,
                // markers: true,
            },
        });
    });

};
export default function CONCEPT() {
    $(() => {
        Promise.all([window.load['concept-01']]).then(gsapAnimation);
    });
}
