const getQueryParam = () => {
    // クエリパラメータを取得する関数
    function getQueryParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    // テキストを取得
    const text = getQueryParam('text');
    console.log(text);

    // テキストがある場合、textareaにセット
    if (text) {
        const textarea = document.querySelector('textarea[name="area_0"]');
        if (textarea) {
            textarea.value = decodeURIComponent(text);
        }
    }
};
export default function CONTACT() {
    $(() => {
      getQueryParam();
    });
}
